.monitord-div-container {
  margin-left: 5%;
}

.monitord-get-health-container {
  width: 50%;
}

.monitord-add-health-container {
  width: 50%;
}

.monitord-result {
}